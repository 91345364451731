import React, {useState, useEffect, useRef} from "react";
import {getLink} from "../routes";
import {Link} from 'gatsby';
import css from './buttonBackTo.module.scss';
import useStateRef from "../../hooks/useStateRef";
import MainButton, {mainButtonArrow} from "../MainButton/MainButton";

const ButtonBackTo = props => {
    const {
        label,
        route,
        blackBg = false,
        nextSectionID = null
    } = props;

    const isClient = typeof window === 'object';

    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    let nextSection = null;

    const [isButtonFixed, setIsButtonFixed, isButtonFixedRef] = useStateRef(true);

    const [buttonHeight, setButtonHeight] = useState(44);
    const buffer = buttonHeight / 2;

    const checkButtonHeight = () => {
        if (buttonRef.current) {
            const buttonBoundingClientRect = buttonRef.current.getBoundingClientRect();
            setButtonHeight(buttonBoundingClientRect.height);
        }
    }

    useEffect(() => {
        if (!isClient) {
            return false;
        }
        nextSection = nextSectionID ? document.getElementById(nextSectionID) : containerRef.current.nextSibling;

        const placeButton = () => {
            const nextSectionTop = nextSection.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            setIsButtonFixed(nextSectionTop + buffer > windowHeight);
        }

        placeButton();

        setTimeout(() => {
            checkButtonHeight();
            placeButton();
        }, 200)

        const handleResize = () => {
            placeButton();
        }

        const handleScroll = () => {
            placeButton();
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        }

    }, []);

    const positionClass = isButtonFixedRef.current ? css.linkFixed : css.linkRelative;

    return (
        <div
            ref={containerRef}
            className={css.wrapper}
            style={{height: buttonHeight}}
        >
            {blackBg && <div className={css.wrapperBg}/>}
            <Link
                to={getLink([route])}
                className={[css.link, positionClass].join(' ')}
            >
                <MainButton
                    label={label}
                    arrow={mainButtonArrow.left}
                    componentRef={buttonRef}
                />
            </Link>
        </div>
    );
};

export default ButtonBackTo;